/* global jQuery:false */


/*
 * training tabs
 */
(function ($) {

	"use strict";

	$(".js-q-training-tab").on("click", function(){

		//event.preventDefault();

		// get active tab target ID
		var tab = $(this).data("tab");

		// enable active tab
		$(".js-q-training-tab").removeClass("q-active-tab");
		$(this).addClass("q-active-tab");

		// show active tab content
		$(".q-training__tabs-content").addClass("q-tab-hidden");
		$(tab).removeClass("q-tab-hidden");

	});

	// get URL hash
	var hash = window.location.hash;

	// enable tabs by hash value
	if(hash && $("[data-tab='" + hash + "-tab']").length){
		$("[data-tab='" + hash + "-tab']").trigger("click");
	}


	$(".js-q-training-tab-instructor").on("click", function(){
		$(".js-q-training-tab").removeClass("q-active-tab");
		$("[href='#instructor-led']").addClass("q-active-tab");
		$(".q-training__tabs-content").addClass("q-tab-hidden");
		$("#instructor-led-tab").removeClass("q-tab-hidden");
		$.fancybox.close();
	});

}(jQuery));

/*
 * automatically open self-paced overlay based on hash value
 */
(function ($) {
	"use strict";

	$(document).ready(function () {
		// get hash value
		var hash = "";
		if (window.location.hash) {
			/* Puts hash in variable, and removes the # character */
			hash = window.location.hash.substring(1);
			/* remove initial slash if it exists */
			hash = hash.indexOf("/") == 0 ? hash.substring(1) : hash;
		}

		// trigger the click on hash selectors

		$("[href='#course_"+ hash +"']").trigger( "click" );

	});
}(jQuery));

/*
 * instructor training dropdown
 */
(function ($) {
	"use strict";

	$(document).ready(function () {

		var all = true;

		$(".js-classes-filter").on("change", function() {

			var filter = $(this).find(":selected").val();

			setTimeout(function(){
				if( filter !== "all" ) {
					all = false;
					$(".js-classes-table-container .js-course-row").hide();
					setTimeout(function(){
						$(".js-classes-table-container .js-course-row[data-id='"+filter+"']").show();
					}, 100);
				}
				else if(!all) {
					$(".js-classes-table-container .js-course-row").show();
					all = true;
				}
			}, 100);
		});

		$(".js-course-row .js-handle").each( function() {
			var span = $(this),
				row = span.parents(".js-course-row");

			span.on("click", function() {
				row.toggleClass("expand");
			});
		});


	});
}(jQuery));
